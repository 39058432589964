<template>
  <v-server-table
    :url="url"
    :columns="columns"
    :options="options"
    :ref="ref ?? 'table'"
    :name="name ?? 'dataTable'"
    @loaded="onLoaded"
  >
    <template v-slot:edit="props">
      <div class="text-center">
        <ActionButtons
          :data="props.row"
          :actions="actions"
          @yes="$emit('yes')"
          :title="modalTitle ?? columns[0]"
        />
      </div>
    </template>
    <template v-slot:extra_actions="props">
      <div class="">
        <ActionButtons
          :data="props.row"
          :actions="extra_actions"
          :title="modalTitle ?? columns[0]"
        />
      </div>
    </template>
    <template v-slot:invoice_status="props">
      <div class="text-start">
        <invoice-status :status="props.row.invoice_status" :short="true" />
      </div>
    </template>
     <template v-slot:status="props">
      <div :class="props.row.status=='Active' ? 'text-success' : 'text-danger'">
        {{ props.row.status }}
      </div>
    </template>
    <template v-slot:comment="props">
      <span
        v-if="props.row.comment && props.row.comment.length > 25"
        :title="props.row.comment"
        @click="$emit('showComment', props.row.comment)"
      >
        {{ props.row.comment.substr(0, 25) }}...
      </span>
      <span v-else>{{ props.row.comment }}</span>
    </template>
    <template v-slot:comment2="props">
      <span
        v-if="props.row.comment2 && props.row.comment2.length > 25"
        :title="props.row.comment2"
        @click="$emit('showComment', props.row.comment2)"
      >
       {{ props.row.comment2.substr(0, 25) }}...
      </span>
      <span v-else>{{ props.row.comment2 }}</span>
    </template>
    <template v-slot:afterFilterWrapper>
      <button
        type="button"
        class="btn btn-light bi bi-x-lg"
        @click="clearFilter"
      />
      <slot name="tableExtraOptions"></slot>
    </template>
    <slot></slot>
  </v-server-table>
</template>

<script>
import http from "../../utils/http-common";
import dateUtils from "../../utils/date-common";
import ActionButtons from "./ActionButtons.vue";
import InvoiceStatus from "../invoices/InvoiceStatus";

export default {
  components: {
    ActionButtons,
    InvoiceStatus,
  },
  props: [
    "url",
    "columns",
    "headings",
    "templates",
    "actions",
    "extra_actions",
    "name",
    "modalTitle",
    "noSort",
    "ref",
  ],
  methods: {
    async onRefresh() {
      this.$refs.table.setOrder(null, true);
      // this.$refs.table.refresh();
    },
    onDownloadCsv() {
      return this.$refs.table.data;
    },
    onLoaded(data) {
      this.$emit("loaded", data);
    },
    async clearFilter() {
      this.$refs.table.resetQuery();
      // this.$refs.table.refresh();
    },
  },
  computed: {
    showModal() {
      return this.$store.state.app.status.showModal;
    },
  },
  watch: {
    showModal(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.$refs.table.setRequestParams();
      }
    },
  },
  data() {
    return {
      options: {
        perPage: 10,
        alwaysShowPerPageSelect: true,
        perPageValues: [5, 10, 15, 25, 50, 100, 200, 300, 500, 700],
        addSortedClassToCells: true,
        saveState: true,
        sortIcon: {
          base: "bi",
          up: "bi-arrow-up",
          down: "bi-arrow-down",
          is: "bi-arrow-down-up",
        },
        sortable: this.columns.filter(
          (c) =>
            c != "edit" &&
            c != "extra_actions" &&
            !(this.noSort ?? []).includes(c)
        ),
        headings: this.headings,
        pagination: {
          show: true,
          dropdown: false,
          chunk: 10,
          edge: true,
          align: "center",
          nav: "scroll",
        },
        texts: {
          count: "Prikaz {from} do {to} od {count} redova||",
          first: "Prva",
          last: "Poslednja",
          filter: "Pretraga:",
          filterPlaceholder: "",
          limit: "Prikaži po:",
          page: "Strana:",
          noResults: "Nema podataka",
          noRequest: "Please select at least one filter to fetch results",
          filterBy: "Pronađi po {column}",
          loading: "Učitavanje...",
          defaultOption: "Izaberi {column}",
          columns: "Kolone",
        },
        rowClassCallback: () => {
          return `${this.name ?? "dataTable"}_table_row`;
        },
        // rowAttributesCallback: () => {
        //   return { style: "background-color: red" };
        // },
        // requestAdapter(data) {
        //   // {
        //   //     "query": "abs",
        //   //     "limit": 1,
        //   //     "ascending": 1,
        //   //     "orderBy": ,
        //   //     "page": 1,
        //   //     "byColumn": 0
        //   // }
        //   return {
        //     sort: data.orderBy ? data.orderBy : "username",
        //     direction: data.ascending ? "asc" : "desc",
        //     filter: data.query ? data.query : "",
        //   };
        // },
        responseAdapter({ data }) {
          return {
            data: data.rows,
            count: data.count,
          };
        },
        requestFunction(data) {
          return http.get(this.url, { params: data }).catch(function (e) {
            this.dispatch("error", e);
          });
        },
        filterable: true,
        filterByColumn: false,
        templates: {
          createdAt(h, row) {
            return dateUtils.formatDate(row.createdAt);
          },
          updatedAt(h, row) {
            return dateUtils.formatDate(row.updatedAt);
          },
          "cession_invoice.doc_date"(h, row) {
            return dateUtils.formatDate(
              row.cession_invoice ? row.cession_invoice.doc_date : null
            );
          },
          cession_date(h, row) {
            return dateUtils.formatDate(row.cession_date);
          },
          ...this.templates,
        },
      },
    };
  },
};
</script>
<style>
.VueTables__table > thead {
  /* font-size: small; */
  white-space: nowrap;
}

.VueTables__table > thead > tr > .VueTables__sortable > .pull-right {
  margin-left: 10px;
  float: none !important;
}

.VueTables__wrapper {
  max-height: 70vh;
  overflow: auto;
}

.VueTables__row {
  vertical-align: middle;
}
/* ensure first header cell sits atop everything else when scrolling right
.VueTables__table > thead > tr > th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}
.VueTables__table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  border-right-color: #aaa;
}
[role="region"][aria-labelledby][tabindex] {
  width: 100%;
  max-height: 98vh;
  overflow: auto;
}
[role="region"][aria-labelledby][tabindex]:focus {
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  outline: 0;
} */
</style>