<template>
  <div class="btn-group me-2" role="group" aria-label="actions">
    <button
      v-for="(val, index) in Object.keys(actions)"
      :key="index"
      type="button"
      :class="`btn btn-${actions[val]['color'] ?? 'primary'}`"
      @click="selectedAction(val)"
      :disabled="actions[val]['disabled']"
    >
      <div
        :class="`d-inline-flex align-items-center bi ${
          actions[val]['icon'] ?? 'bi-pencil'
        }`"
      >
      <span v-if="actions[val]['pref']">{{actions[val]['pref']}}</span>
        {{
          actions[val]["countIcon"] && !Array.isArray(data[actions[val]["countIcon"]]) && data[actions[val]["countIcon"]] > 0
            ? `&nbsp;${data[actions[val]["countIcon"]]}`
            : ""
        }}
        {{
          actions[val]["countIcon"] && data[actions[val]["countIcon"]] && Array.isArray(data[actions[val]["countIcon"]]) && data[actions[val]["countIcon"]].length > 0
            ? `&nbsp;${data[actions[val]["countIcon"]].length}`
            : ""
        }}
        {{
          actions[val]["countIcon"] && data.invoices
            ? `&nbsp;${
                data.invoices.filter(
                  (x) => x.invoice_type == actions[val]["countIcon"]
                ).length
              }`
            : ""
        }}
        {{
          actions[val]["count"] && data[actions[val]["count"]]
            ? `&nbsp;${data[actions[val]["count"]].length}`
            : ""
        }}
      </div>
    </button>
  </div>
</template>



<script>
import ConfirmDialog from "./ConfirmDialog.vue";

export default {
  name: "action-buttons",
  props: ["data", "actions", "title", "countIcon"],
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    selectedAction(val) {
      if (this.actions[val]["action"]) {
        this.actions[val]["action"](this.data);
      } else {
        switch (val) {
          case "edit":
            this.editData();
            break;
          case "delete":
            this.deleteData();
            break;
          case "print":
            this.printAction();
            break;
          default:
            break;
        }
      }
    },
    confirmYes() {
      this.actions.delete.deleteData(this.data.id);
    },
    confirmNo() {
      this.$store.commit("app/hideModal");
    },
    printAction() {
      // window.open(
      //   `${this.actions.print.url}/${this.data.id}`,
      //   "",
      //   "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      // );
      // Popup with options
    },
    editData() {
      // Set current Entity
      // if (this.$router.currentRoute.value.name == "sellings") {
      //   this.$store.commit("app/setCurrentSelling", this.data);
      // }
      // if (this.$router.currentRoute.value.name == "cessions") {
      //   this.$store.commit("app/setCurrentCession", this.data);
      // }
      this.$store.commit("app/showModal", {
        modal: this.actions.edit.commp,
        params: {
          data: this.data,
          title: "Izmeni: " + this.data[this.title],
        },
      });
    },
    deleteData() {
      this.$store.commit("app/showModal", {
        modal: ConfirmDialog,
        params: {
          message: "Da li ste sigurni?",
          title: "Obrisi: " + this.data[this.title],
          onYes: this.confirmYes,
          onNo: this.confirmNo,
          // foother: { isComponent: true, commponent: test },
        },
      });
    },
  },
};
</script>
<style scoped>
.btn-indigo {
  color: white;
  background-color: indigo;
}
.btn-orange {
  color: white;
  background-color: orange;
}
.btn-teal {
  color: white;
  background-color: teal;
}
.btn-purple {
  color: white;
  background-color: purple;
}
</style>